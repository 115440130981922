/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import LinkIcon from '@atlaskit/icon/core/migration/link';
import { N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch';

const iconWrapperStyles = css({
	display: 'flex',
	width: '20px',
	height: '20px',
	borderRadius: '50%',
	alignItems: 'center',
	justifyContent: 'center',
	boxShadow: token('elevation.shadow.raised', '0 2px 4px 0 rgba(0, 0, 0, 0.25)'),
});

const iconWrapperExtraPadding = css({
	padding: token('space.025'),
});

type Props = {
	color: string;
};

const DependencyDragHandler = ({ color }: Props) => (
	<div
		css={[iconWrapperStyles, isVisualRefreshEnabled() && iconWrapperExtraPadding]}
		style={{ backgroundColor: color }}
	>
		<LinkIcon LEGACY_size="small" label="" color={token('color.icon.inverse', N0)} />
	</div>
);

export { DependencyDragHandler };
