import React, { forwardRef, useMemo, useCallback, type MouseEvent } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import HighlightText from '@atlassian/jira-software-highlight-text/src/ui/index.tsx';
import {
	SummaryContainer as Container,
	SummaryIcon,
	SummaryTitle,
	SummaryKeyLink,
} from '../../../common/ui/summary';
import messages from './messages';
import ProgressIndicator, { type ProgressMetadata } from './progress-indicator';

type Props = {
	isDone: boolean;
	title: string;
	iconUrl: string;
	itemKey?: string;
	iconAltText: string;
	highlightText: string;
	progressMetadata: ProgressMetadata;
	isActive: boolean;
};

export const onClickOld = (event: MouseEvent<HTMLAnchorElement>) => {
	const withCmd = event.ctrlKey || event.metaKey;
	// We do not want to navigate away when clicking unless there is explicit intent
	// In most cases, a click should open up the item details
	withCmd ? event.stopPropagation() : event.preventDefault();
};

const onPress = (e: React.KeyboardEvent<HTMLAnchorElement>, link: string) => {
	if (e.key === 'Enter') {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.open(link, '_blank');
	}
};

const TooltipTag = forwardRef<HTMLElement>((props, ref) => (
	// eslint-disable-next-line react/jsx-props-no-spreading
	<Box xcss={tooltipTag} ref={ref} {...props} />
));

const Summary = ({
	title,
	iconUrl,
	isDone,
	itemKey,
	iconAltText,
	highlightText,
	isActive,
	progressMetadata,
}: Props) => {
	const highlight = useMemo(() => [highlightText], [highlightText]);
	const { formatMessage } = useIntl();

	const onClick = useCallback((event: MouseEvent<HTMLAnchorElement>) => {
		const withCmd = event.ctrlKey || event.metaKey;
		// We do not want to navigate away when clicking unless there is explicit intent
		// In most cases, a click should open up the item details
		withCmd ? event.stopPropagation() : event.preventDefault();
	}, []);

	const onKeyDown = useCallback(
		(event: React.KeyboardEvent<HTMLAnchorElement>) => {
			if (event.key !== 'Enter') {
				return;
			}

			event.stopPropagation();

			const link = `/browse/${itemKey}`;
			const withCmd = event.ctrlKey || event.metaKey;

			if (withCmd) {
				event.preventDefault();

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				window.open(link, '_blank');
			}
		},
		[itemKey],
	);

	const itemKeyEl =
		highlightText !== '' && itemKey !== undefined ? (
			<HighlightText
				data-testid="roadmap.timeline-table-kit.ui.list-item-content.summary.item-el"
				text={itemKey}
				highlight={highlight}
			/>
		) : (
			itemKey
		);

	const titleEl =
		highlightText !== '' ? (
			<HighlightText
				data-testid="roadmap.timeline-table-kit.ui.list-item-content.summary.title-el"
				text={title}
				highlight={highlight}
			/>
		) : (
			title
		);

	const renderSummaryTitle = () => (
		<SummaryTitle type="p" testId="roadmap.timeline-table-kit.ui.list-item-content.summary.title">
			{titleEl}
		</SummaryTitle>
	);

	return (
		<>
			<Container>
				<SummaryIcon src={iconUrl} alt={iconAltText} />
				{itemKey && (
					<SummaryKeyLink
						href={`/browse/${itemKey}`}
						isDone={isDone}
						testId="roadmap.timeline-table-kit.ui.list-item-content.summary.key"
						aria-label={formatMessage(
							expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
								? messages.issueKeyLabelIssueTermRefresh
								: messages.issueKeyLabel,
							{ itemKey },
						)}
						onClick={fg('timeline_grid_navigation_m2') ? onClick : onClickOld}
						onKeyDown={
							fg('timeline_grid_navigation_m2')
								? onKeyDown
								: (e) => onPress(e, `/browse/${itemKey}`)
						}
					>
						{itemKeyEl}
					</SummaryKeyLink>
				)}
				{title.length > 16 ? (
					<Tooltip
						position="bottom-start"
						content={titleEl}
						{...(fg('jsw_fix_timeline_issue_summary_missing_ellipses') && {
							tag: TooltipTag,
						})}
					>
						{renderSummaryTitle()}
					</Tooltip>
				) : (
					renderSummaryTitle()
				)}
			</Container>
			{progressMetadata.total > 0 && (
				<ProgressIndicator {...progressMetadata} isActive={isActive} />
			)}
		</>
	);
};

const tooltipTag = xcss({
	overflow: 'hidden',
});

const DEFAULT_PROGRESS_META = {
	completed: 0,
	total: 0,
};

Summary.defaultProps = {
	isDone: false,
	iconAltText: '',
	highlightText: '',
	progressMetadata: DEFAULT_PROGRESS_META,
};

export default Summary;
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { ProgressMetadata };
