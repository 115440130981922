import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	issueKeyLabel: {
		id: 'roadmap.timeline-table-kit.list-item-content.summary.issue-key-label',
		defaultMessage: 'issue key {itemKey}',
		description: 'Accessibility label for a issue key link.',
	},
	issueKeyLabelIssueTermRefresh: {
		id: 'roadmap.timeline-table-kit.list-item-content.summary.issue-key-label-issue-term-refresh',
		defaultMessage: 'issue key {itemKey}',
		description: 'Accessibility label for a issue key link.',
	},
});
