import React, { useCallback, type MouseEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import CustomizeIcon from '@atlaskit/icon/core/migration/customize--preferences';
import { Box, xcss, media } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import traceUFOPress from '@atlassian/react-ufo/trace-press';
import messages from './messages';

type Props = {
	isSelected: boolean;
	onClick: () => void;
};

export const ViewSettingsButton = ({ isSelected, onClick }: Props) => {
	const { formatMessage } = useIntl();

	const handleClick = useCallback(
		(event: MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			traceUFOPress('timeline-view-settings-clicked', event.timeStamp);
			fireUIAnalytics(analyticsEvent, 'viewSettingsButton');
			onClick();
		},
		[onClick],
	);

	return (
		<Box xcss={buttonWrapperStyles}>
			<Box xcss={largeButtonStyles}>
				<Button
					testId="roadmap.standard-roadmap.header.view-settings.button.large"
					aria-label={formatMessage(messages.viewSettings)}
					appearance="default"
					isSelected={isSelected}
					onClick={handleClick}
					iconBefore={<CustomizeIcon label="" spacing="spacious" color="currentColor" />}
				>
					{formatMessage(messages.viewSettings)}
				</Button>
			</Box>
			<Box xcss={smallButtonStyles}>
				<Tooltip content={formatMessage(messages.viewSettings)}>
					<Button
						testId="roadmap.standard-roadmap.header.view-settings.button.small"
						aria-label={formatMessage(messages.viewSettings)}
						appearance="default"
						isSelected={isSelected}
						onClick={handleClick}
						iconBefore={<CustomizeIcon label="" spacing="spacious" color="currentColor" />}
					/>
				</Tooltip>
			</Box>
		</Box>
	);
};

export default ViewSettingsButton;

const largeButtonStyles = xcss({
	display: 'var(--large-button-visibility, none)',
	[media.above.md]: {
		display: 'var(--large-button-visibility, block)',
	},
});

const smallButtonStyles = xcss({
	display: 'var(--small-button-visibility, block)',
	[media.above.md]: {
		display: 'var(--small-button-visibility, none)',
	},
});

const buttonWrapperStyles = xcss({
	marginLeft: 'space.100',
});
